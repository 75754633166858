import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Layout from "../components/Layout";

import { Home } from "../containers/Home";
import { Menu } from "../containers/Menu";
import { Cocktail } from "../containers/Cocktail";
import { AboutUs } from "../containers/AboutUs";
// import { Reservation } from "../containers/Reservation";

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/nuestra-carta" component={Menu} />
          <Route exact path="/cocteleria" component={Cocktail} />
          <Route exact path="/nuestro-cuento" component={AboutUs} />
          {/* <Route exact path="/reservas" component={Reservation} /> */}
          <Redirect to="/" />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
