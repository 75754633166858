import React from "react";

import "../styles/components/Footer.scss";

function Footer() {
  return (
    <footer>
      <div className="Footer-elements" />
    </footer>
  );
}

export default Footer;
