import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Fade from "react-reveal/Fade";
import config from "react-reveal/globals";

// Styles
import "../styles/components/Header.scss";

// Statis assets
import logoGold from "../static/logo.svg";
import logoBlack from "../static/logo-black.svg";
import iconFb from "../static/Facebook_Out.svg";
import iconIg from "../static/Instagram_Out.svg";
import iconFbOut from "../static/Facebook_Over.svg";
import iconIgOut from "../static/Instagram_Over.svg";
import iconLocation from "../static/icon-locat.svg";
import iconMenu from "../static/icon-menu.svg";
import lineMenu from "../static/line-menu.svg";

config({ ssrFadeout: true });

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen === true) {
      setIsMenuOpen(false);
      document.querySelector("body").classList.remove("blocked");
    } else {
      setIsMenuOpen(true);
      document.querySelector("body").classList.add("blocked");
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.querySelector("body").classList.remove("blocked");
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <header className={isMenuOpen ? "active" : ""}>
            <div className="Header-elements">
              <div className="Header-img">
                <div className="Header-img__container">
                  <Fade ssrFadeout big>
                    <img src={logoBlack} alt="" />
                  </Fade>
                </div>
              </div>

              <div className="Header-btn">
                <div className="Header-btn__container">
                  <Fade ssrFadeout>
                    <button
                      type="button"
                      onClick={() => {
                        showMenu();
                      }}
                    >
                      <div className="line first">
                        <span />
                        <span />
                      </div>

                      <div className="line second">
                        <span />
                        <span />
                      </div>

                      <div className="line third">
                        <span />
                        <span />
                      </div>
                    </button>
                  </Fade>
                </div>
              </div>
            </div>
          </header>

          <div className={isMenuOpen ? "Menu-float active" : "Menu-float"}>
            <div className="Menu-float-img">
              <div className="Header-img__container">
                <img src={logoGold} alt="" />
              </div>

              <div className="Header-btn__container">
                <button
                  type="button"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  <span />
                  <span />
                </button>
              </div>
            </div>

            <div className="Menu-float__list">
              <ul>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="is-active"
                    to="/"
                    onClick={closeMenu}
                  >
                    <img src={lineMenu} alt="" />
                    <div className="floatIcon">
                      <img src={iconMenu} alt="" />
                    </div>
                    <span>Home.</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="is-active"
                    to="/nuestro-cuento"
                    onClick={closeMenu}
                  >
                    <img src={lineMenu} alt="" />
                    <div className="floatIcon">
                      <img src={iconMenu} alt="" />
                    </div>
                    <span>Nuestro</span>
                    <span>cuento.</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="is-active"
                    to="/nuestra-carta"
                    onClick={closeMenu}
                  >
                    <img src={lineMenu} alt="" />
                    <div className="floatIcon">
                      <img src={iconMenu} alt="" />
                    </div>
                    <span>Nuestra</span>
                    <span>carta.</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={true}
                    activeClassName="is-active"
                    to="/cocteleria"
                    onClick={closeMenu}
                  >
                    <img src={lineMenu} alt="" />
                    <div className="floatIcon">
                      <img src={iconMenu} alt="" />
                    </div>
                    <span>Cocteleria.</span>
                  </NavLink>
                </li>
              </ul>

              <div className="Menu-float__list__rrss">
                <span>Siguenos:</span>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/CaperucitaValpo/"
                      rel="noreferrer"
                    >
                      <img src={iconFb} alt="fb" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/lacaperucitayellobo/"
                      rel="noreferrer"
                    >
                      <img src={iconIg} alt="tw" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="Menu-float__information">
              <div className="Menu-float__information__content">
                <ul className="Menu-float__list__content">
                  <li>
                    <img src={iconLocation} alt="" />
                  </li>
                  <li>Ferrari #75, Cerro Florida, Valparaíso.</li>
                  <li>Teléfono: +56 3 2317 2798</li>
                  <li>Mail: contacto@lacaperucitayellobo.cl</li>
                </ul>

                <div className="Menu-float__rrss__content">
                  <span>Siguenos:</span>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/CaperucitaValpo/"
                        rel="noreferrer"
                      >
                        <img src={iconFb} alt="fb" />
                        <img src={iconFbOut} alt="fb" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/lacaperucitayellobo/"
                        rel="noreferrer"
                      >
                        <img src={iconIg} alt="tw" />
                        <img src={iconIgOut} alt="tw" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Header;
