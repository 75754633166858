/* eslint-disable no-unreachable */

import "../styles/containers/Home.scss";

import React, { useEffect, useRef, useState } from "react";

import BorderButton from "../components/BorderButton";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import config from "react-reveal/globals";
import discovery from "../static/home-images/50discovey.svg";
import iconCalendar from "../static/icon-calendar.svg";
import iconCaperucita from "../static/icon-caperucita.svg";
import iconLobo from "../static/icon-lobo.svg";
import imgDesktop1 from "../static/home-images/home-desktop-1.png";
import imgDesktop2 from "../static/home-images/home-desktop-2.png";
import imgDesktop3 from "../static/home-images/home-desktop-3.png";
import imgDesktop4 from "../static/home-images/home-desktop-4.png";
import imgMobile1 from "../static/home-images/home-mobile-1.png";
import imgMobile2 from "../static/home-images/home-mobile-2.png";
import imgMobile4 from "../static/home-images/home-mobile-4.png";
import logoBlack from "../static/logo-black.svg";
import logoLcyel from "../static/logo-lcyel.svg";
import winner from "../static/Travelers_choice.png";

// Components

// Styles

// Statis assets

// import iconWsp from '../static/icon-wsp.svg';

// import imgMobile3 from "../static/home-images/home-mobile-3.png";

config({ ssrFadeout: true });

export const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const booking = useRef();

  const handleBooking = () => {
    booking.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <section className="Home-section">
            <div className="Home-content first">
              <div className="Home-content__image">
                <Fade ssrFadeout left>
                  <img className="floatBrand" src={winner} alt="" />
                </Fade>

                <Fade ssrFadeout right>
                  <a target="_blank" href="https://www.theworlds50best.com/discovery/Establishments/Chile/Valparaiso/La-Caperucita-y-el-Lobo.html" rel="noreferrer">
                    <img src={discovery} alt="" className="floatDiscoery" />
                  </a>
                </Fade>

                <Fade ssrFadeout>
                  <picture>
                    <source media="(min-width: 767px)" srcSet={imgDesktop1} />
                    <img src={imgMobile1} alt="" />
                  </picture>
                  f
                </Fade>
              </div>

              <div className="Home-content__information">
                <div className="Home-information__logo">
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt="" />
                  </Fade>
                </div>
                <Fade ssrFadeout big cascade>
                  <div className="Home-content__description">
                    <h2>
                      Como salido <br /> de un cuento.
                    </h2>

                    <p>
                      La coquetería de La Caperucita y el instinto salvaje del Lobo, dan vida a este restaurant emplazado en la casa de la abuelita. Sabores que nos relatan
                      historias y sacian esa hambre feroz de disfrutar como niños.
                    </p>

                    <div className="Home-content__actions">
                      <a href="https://lacaperucitayellobo.mesa247.la/" target="_blank" rel="noreferrer">
                        <BorderButton />
                        <img src={iconCalendar} alt="" />
                        <span>Haz tu reserva</span>
                      </a>

                      <a target="_blank" href="https://wa.me/56989010988" rel="noreferrer">
                        <BorderButton />
                        <img src={iconCalendar} alt="" />
                        <span>Contáctanos</span>
                      </a>
                    </div>
                  </div>
                </Fade>

                <div className="Home-information__scroll right">
                  <Fade ssrFadeout bottom>
                    <a target="_blank" href="https://www.theworlds50best.com/discovery/Establishments/Chile/Valparaiso/La-Caperucita-y-el-Lobo.html" rel="noreferrer">
                      <img src={discovery} alt="" className="floatDiscoery" />
                    </a>
                  </Fade>

                  <Fade ssrFadeout bottom>
                    <img src={iconLobo} alt="" />
                  </Fade>
                </div>
              </div>
            </div>

            <div className="Home-content second">
              <div className="Home-content__image">
                <picture>
                  <source srcSet={imgDesktop2} media="(min-width: 767px)" />
                  <Fade ssrFadeout>
                    <img src={imgMobile2} alt="" />
                  </Fade>
                </picture>
              </div>

              <div className="Home-content__information">
                <div className="Home-information__logo">
                  <Fade ssrFadeout big>
                    <img src={logoBlack} alt="" />
                  </Fade>
                </div>

                <div className="Home-content__description">
                  <Fade ssrFadeout big>
                    <h2>Érase una vez, la casa de una abuelita.</h2>
                    <p>
                      El restaurant se emplaza en la que fue la casa de la abuelita, amante de las historias y las reuniones familiares. Aquí su nieta, La Caperucita, formó su
                      familia junto al Lobo, viviendo felices y comiendo perdices (aunque no están en la carta).
                    </p>

                    <div className="Home-content__actions">
                      <Link to="/nuestro-cuento">
                        <BorderButton />
                        <span>Conoce el restaurante</span>
                      </Link>
                    </div>
                  </Fade>
                </div>

                <div className="Home-information__scroll left">
                  <Fade ssrFadeout bottom>
                    <img src={iconCaperucita} alt="" />
                  </Fade>
                </div>
              </div>
            </div>

            <div className="Home-content third">
              <div className="Home-content__image">
                <picture>
                  <source srcSet={imgDesktop3} media="(min-width: 768px)" />
                  <Fade ssrFadeout>
                    <img src={imgDesktop3} alt="" />
                  </Fade>
                </picture>
              </div>

              <div className="Home-content__information">
                <div className="Home-information__logo">
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt="" />
                  </Fade>
                </div>

                <div className="Home-content__description">
                  <Fade ssrFadeout big>
                    <h2>
                      Para <br /> comer(te) <br /> mejor.
                    </h2>

                    <p>
                      El alma de las recetas de la abuelita con una vuelta audaz. Preparaciones con harto cuento, organizadas en tres actos que se complementan en una experiencia
                      gastronómica de texturas, aromas y sabor.
                    </p>

                    <div className="Home-content__actions">
                      <Link to="/nuestra-carta">
                        <BorderButton />
                        <span>Revisa nuestra carta</span>
                      </Link>
                    </div>
                  </Fade>
                </div>

                <div className="Home-information__scroll">
                  <Fade ssrFadeout bottom>
                    <img src={iconLobo} alt="" />
                  </Fade>
                </div>
              </div>
            </div>

            <div className="Home-content fourth">
              <div className="Home-content__image">
                <picture>
                  <source srcSet={imgDesktop4} media="(min-width: 768px)" />
                  <Fade ssrFadeout>
                    <img src={imgMobile4} alt="" />
                  </Fade>
                </picture>
              </div>

              <div className="Home-content__information">
                <div className="Home-information__logo">
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt="" />
                  </Fade>
                </div>

                <div className="Home-content__description">
                  <Fade ssrFadeout big>
                    <h2>
                      Coctelería <br /> de autor.
                    </h2>
                    <p>
                      Cócteles de nuestra autoría. Algunos sensuales y delicados como “La Caperucita”, y otros más salvajes y atrevidos como “El Lobo”. Variadas opciones para
                      degustar y disfrutar mirando el mar desde nuestro restaurant o en la comodidad de tu casa.
                    </p>
                    <div className="Home-content__actions">
                      <Link to="/cocteleria">
                        <BorderButton />
                        <span>Descubre tu favorito</span>
                      </Link>
                    </div>
                  </Fade>
                </div>

                <div className="Home-information__scroll">
                  <Fade ssrFadeout bottom>
                    <img src={iconCaperucita} alt="" />
                  </Fade>
                </div>
              </div>
            </div>

            <div className="Home-content aboutUs" ref={booking}>
              <div className="Aboutus-content">
                <div className="Aboutus-content__image">
                  <picture>
                    <source media="(min-width: 768px)" srcSet={logoLcyel} />
                    <Fade ssrFadeout>
                      <img src={logoLcyel} alt="" />
                    </Fade>
                  </picture>
                </div>

                <div className="Aboutus-content__information">
                  <div className="Aboutus-content__description">
                    <Fade ssrFadeout big>
                      <h2>Estaremos felices de que nos visites.</h2>
                      <p>Haz tu reserva para prepararnos de la mejor forma y recibirte a ti y a quienes te acompañen como se merecen.</p>

                      <div className="Aboutus-content__actions">
                        {/* <a
                          target="_blank"
                          href="https://store.lcyel.tk/"
                          rel="noreferrer"
                        >
                          <BorderButton />
                          <img src={iconService} alt="" />
                          <span>Pide a tu casa</span>
                        </a> */}
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
              {/* <Reservation />
               */}
            </div>
          </section>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
