import React, { useEffect, useState, useRef } from "react";
import { Html5Entities } from "html-entities";
// Components
import Fade from "react-reveal/Fade";
import config from "react-reveal/globals";
import Loader from "../components/Loader";

// Styles
import "../styles/containers/Menu.scss";

// Statis assets
import MenuHeader from "../static/menu-images/menu-mobile.png";
import MenuHeaderDesktop from "../static/menu-images/menu-desktop.png";
import logoBlack from "../static/logo-black.svg";
import ArrowDown from "../static/arrow-down.svg";
import ArrowDownWhite from "../static/arrow-down-white.svg";

config({ ssrFadeout: true });
const htmlEntities = new Html5Entities();

export const Menu = () => {
  const [postFirstAct, setPostFirstAct] = useState([]);
  const [postSecondtAct, setPostSecondAct] = useState([]);
  const [postThirdAct, setPostThirdAct] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const firstAct = useRef();
  const secondAct = useRef();
  const thirdAct = useRef();

  const handleFirstAct = () => {
    firstAct.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSecondAct = () => {
    secondAct.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleThirdAct = () => {
    thirdAct.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);

    async function loadPostsFirstAct() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/primer_acto"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postFirstAct = await response.json();
        setPostFirstAct(postFirstAct);
      } catch (erros) {
        console.log("error fetch");
      }
    }

    async function loadPostsSecondAct() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/segundo_acto"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postSecondtAct = await response.json();
        setPostSecondAct(postSecondtAct);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadPostsThirdAct() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/tercer_acto"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postThirdAct = await response.json();
        setPostThirdAct(postThirdAct);
      } catch (error) {
        console.log("error fetch");
      }
    }

    loadPostsFirstAct();
    loadPostsSecondAct();
    loadPostsThirdAct();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <section className="Menu-section">
            <div className="Menu-content">
              <div className="Menu-content__img">
                <picture>
                  <source
                    media="(min-width: 768px)"
                    srcSet={MenuHeaderDesktop}
                  />
                  <Fade ssrFadeout>
                    <img src={MenuHeader} alt="" />
                  </Fade>
                </picture>
              </div>

              <div className="Menu-content__information">
                <div className="Home-information__logo">
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt="" />
                  </Fade>
                </div>

                <div className="Menu-content__desc">
                  <Fade ssrFadeout>
                    <h2>
                      Para <br /> comer(te) <br /> mejor.
                    </h2>
                    <p>
                      Un paseo a través de delicados sabores que invitan a
                      disfrutar como niños. Revisa nuestro menú de tres tiempos
                      para abrir el apetito.
                    </p>
                  </Fade>
                </div>

                <div className="Menu-actions">
                  <Fade ssrFadeout cascade>
                    <ul className="Menu-actions__list">
                      <li onClick={handleFirstAct}>
                        <img src={ArrowDownWhite} alt="" />
                        <span>
                          Primer <br /> acto
                        </span>
                        <span>(Entradas)</span>
                      </li>
                      <li onClick={handleSecondAct}>
                        <img src={ArrowDownWhite} alt="" />
                        <span>
                          Segundo <br /> acto
                        </span>
                        <span>(Fondos)</span>
                      </li>
                      <li onClick={handleThirdAct}>
                        <img src={ArrowDownWhite} alt="" />
                        <span>
                          Tercer <br /> acto
                        </span>
                        <span>(Postres)</span>
                      </li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </section>

          <section className="MenuAux-section" ref={firstAct}>
            <div className="MenuAux-content">
              <Fade ssrFadeout>
                <h2>Primer Acto</h2>
                <span>(Entradas)</span>
              </Fade>

              <div className="MenuAux-content__arrow">
                <Fade ssrFadeout>
                  <img src={ArrowDown} alt="" />
                </Fade>
              </div>
            </div>
          </section>

          {/* Primer acto */}
          {postFirstAct.length >= 1 ? (
            <section className="Food-section">
              {postFirstAct.map((product) => (
                <div className="Froo-wrap" key={product.id}>
                  <div className="Food-content">
                    <div className="Food-content__image">
                      <div className="Menu-actions">
                        <Fade ssrFadeout>
                          <ul className="Menu-actions__list">
                            <li className="active" onClick={handleFirstAct}>
                              <span>Primer acto</span>
                              <span>(Entradas)</span>
                            </li>
                            <li onClick={handleSecondAct}>
                              <span>Segundo acto</span>
                              <span>(Fondos)</span>
                            </li>
                            <li onClick={handleThirdAct}>
                              <span>Tercer acto</span>
                              <span>(Postres)</span>
                            </li>
                          </ul>
                        </Fade>
                      </div>

                      <div className="Food-wrap__image">
                        <div className="float-square" />
                        <picture>
                          <source
                            media="(min-width: 768px)"
                            srcSet={product.acf.imagen.url}
                          />
                          <Fade ssrFadeout>
                            <img src={product.acf.imagen.url} alt="" />
                          </Fade>
                        </picture>
                      </div>
                    </div>

                    <div className="Food-content__information">
                      <div className="Food-content__description">
                        <Fade ssrFadeout>
                          <h2>{htmlEntities.decode(product.title.rendered)}</h2>
                          {product.veggan ? <span>(Vegano)</span> : ""}
                          <p>{product.acf.descripcion}</p>
                        </Fade>
                      </div>

                      <div className="Food-content__price">
                        <Fade ssrFadeout>
                          <h3>
                            $<span>{product.acf.precio}</span>
                          </h3>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="MenuAux-section" ref={secondAct}>
            <div className="MenuAux-content">
              <Fade ssrFadeout>
                <h2>Segundo Acto</h2>
                <span>(Fondos)</span>
              </Fade>

              <div className="MenuAux-content__arrow">
                <Fade ssrFadeout>
                  <img src={ArrowDown} alt="" />
                </Fade>
              </div>
            </div>
          </section>

          {/* Segundo acto */}
          {postSecondtAct.length >= 1 ? (
            <section className="Food-section">
              {postSecondtAct.map((product) => (
                <div className="Froo-wrap" key={product.id}>
                  <div className="Food-content">
                    <div className="Food-content__image">
                      <div className="Menu-actions">
                        <Fade ssrFadeout>
                          <ul className="Menu-actions__list">
                            <li onClick={handleFirstAct}>
                              <span>Primer acto</span>
                              <span>(Entradas)</span>
                            </li>
                            <li className="active" onClick={handleSecondAct}>
                              <span>Segundo acto</span>
                              <span>(Fondos)</span>
                            </li>
                            <li onClick={handleThirdAct}>
                              <span>Tercer acto</span>
                              <span>(Postres)</span>
                            </li>
                          </ul>
                        </Fade>
                      </div>

                      <div className="Food-wrap__image">
                        <div className="float-square" />
                        <picture>
                          <source
                            media="(min-width: 768px)"
                            srcSet={product.acf.imagen.url}
                          />
                          <Fade ssrFadeout>
                            <img src={product.acf.imagen.url} alt="" />
                          </Fade>
                        </picture>
                      </div>
                    </div>

                    <div className="Food-content__information">
                      <div className="Food-content__description">
                        <Fade ssrFadeout>
                          <h2>{htmlEntities.decode(product.title.rendered)}</h2>
                          {product.veggan ? <span>(Vegano)</span> : ""}
                          <p>{product.acf.descripcion}</p>
                        </Fade>
                      </div>

                      <div className="Food-content__price">
                        <Fade ssrFadeout>
                          <h3>
                            $<span>{product.acf.precio}</span>
                          </h3>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="MenuAux-section" ref={thirdAct}>
            <div className="MenuAux-content">
              <Fade ssrFadeout>
                <h2>Tercer Acto</h2>
                <span>(Postres)</span>
              </Fade>

              <div className="MenuAux-content__arrow">
                <Fade ssrFadeout>
                  <img src={ArrowDown} alt="" />
                </Fade>
              </div>
            </div>
          </section>

          {/* Tercer acto */}
          {postThirdAct.length >= 1 ? (
            <section className="Food-section">
              {postThirdAct.map((product) => (
                <div className="Froo-wrap" key={product.id}>
                  <div className="Food-content">
                    <div className="Food-content__image">
                      <div className="Menu-actions">
                        <Fade ssrFadeout>
                          <ul className="Menu-actions__list">
                            <li onClick={handleFirstAct}>
                              <span>Primer acto</span>
                              <span>(Entradas)</span>
                            </li>
                            <li onClick={handleSecondAct}>
                              <span>Segundo acto</span>
                              <span>(Fondos)</span>
                            </li>
                            <li onClick={handleThirdAct}>
                              <span>Tercer acto</span>
                              <span>(Postres)</span>
                            </li>
                          </ul>
                        </Fade>
                      </div>

                      <div className="Food-wrap__image">
                        <div className="float-square" />
                        <picture>
                          <source
                            media="(min-width: 768px)"
                            srcSet={product.acf.imagen.url}
                          />
                          <Fade ssrFadeout>
                            <img src={product.acf.imagen.url} alt="" />
                          </Fade>
                        </picture>
                      </div>
                    </div>

                    <div className="Food-content__information">
                      <div className="Food-content__description">
                        <Fade ssrFadeout>
                          <h2>{htmlEntities.decode(product.title.rendered)}</h2>
                          {product.veggan ? <span>(Vegano)</span> : ""}
                          <p>{product.acf.descripcion}</p>
                        </Fade>
                      </div>

                      <div className="Food-content__price">
                        <Fade ssrFadeout>
                          <h3>
                            $<span>{product.acf.precio}</span>
                          </h3>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
