import React, { useEffect, useState, useRef } from "react";
import { Html5Entities } from "html-entities";

// Components
import Fade from "react-reveal/Fade";
import config from "react-reveal/globals";
import Loader from "../components/Loader";

// Styles
import "../styles/containers/Cocktail.scss";

// Statis assets
import ArrowDown from "../static/arrow-down.svg";
import CocktailHeader from "../static/cocktail-images/cocktail-desktop.png";
import CocktailHeaderMobile from "../static/cocktail-images/cocktail-mobile.png";
import logoBlack from "../static/logo-black.svg";
import ArrowDownWhite from "../static/arrow-down-white.svg";

config({ ssrFadeout: true });
const htmlEntities = new Html5Entities();

export const Cocktail = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [coctailNormal, setCoctailNormal] = useState([]);
  const [coctailClassic, setCoctailClassic] = useState([]);
  const [champagne, setChampagne] = useState([]);
  const [wineClassic, setWineClassic] = useState([]);
  const [whiteWine, setWhiteWine] = useState([]);
  const [beer, setBeer] = useState([]);
  const [juiceDrinks, setJuiceDrinks] = useState([]);

  const coctail = useRef();
  const wine = useRef();
  const juice = useRef();

  const handleCoctailAct = () => {
    coctail.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleWineAct = () => {
    wine.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleJuiceAct = () => {
    juice.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);

    async function loadCoctailNormal() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/cocteleria"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postCoctailNormal = await response.json();
        setCoctailNormal(postCoctailNormal);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadCoctailClassic() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/clasicos_refrescante"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postCoctailClassic = await response.json();
        setCoctailClassic(postCoctailClassic);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadChampagnes() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/espumantes"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postChampagne = await response.json();
        setChampagne(postChampagne);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadWineClassic() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/vinos_tinto"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postWineClassic = await response.json();
        setWineClassic(postWineClassic);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadWineWhite() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/vinos_blanco"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postWineWhite = await response.json();
        setWhiteWine(postWineWhite);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadBeer() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/cervezas"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postBeer = await response.json();
        setBeer(postBeer);
      } catch (error) {
        console.log("error fetch");
      }
    }

    async function loadJuiceDrinks() {
      try {
        const response = await fetch(
          "https://api.lacaperucitayellobo.cl/wp-json/wp/v2/jugos_y_bebidas"
        );
        if (!response.ok) {
          // oups! something went wrong
          return;
        }

        const postJuiceDrinks = await response.json();
        setJuiceDrinks(postJuiceDrinks);
      } catch (error) {
        console.log("error fetch");
      }
    }

    // Refactorizar!!!!!s
    loadCoctailNormal();
    loadCoctailClassic();
    loadChampagnes();
    loadWineClassic();
    loadWineWhite();
    loadBeer();
    loadJuiceDrinks();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <section className="Menu-section cocktail">
            <div className="Menu-content">
              <div className="Menu-content__img">
                <picture>
                  <source media="(min-width: 768px)" srcSet={CocktailHeader} />
                  <Fade ssrFadeout>
                    <img src={CocktailHeaderMobile} alt="" loading="lazy" />
                  </Fade>
                </picture>
              </div>

              <div className="Menu-content__information">
                <div className="Home-information__logo">
                  <Fade ssrFadeout>
                    <img src={logoBlack} loading="lazy" alt="" />
                  </Fade>
                </div>

                <div className="Menu-content__desc">
                  <Fade ssrFadeout>
                    <h2>
                      Para beber <br /> y compartir
                    </h2>
                    <p>
                      Coctelería de autor, cócteles tradicionales, y una carta
                      de vinos cuidadosamente seleccionados.
                    </p>
                  </Fade>
                </div>

                <div className="Menu-actions">
                  <Fade ssrFadeout>
                    <ul className="Menu-actions__list">
                      <li onClick={handleCoctailAct}>
                        <img src={ArrowDownWhite} alt="" />
                        <span>Cocteles</span>
                      </li>
                      <li onClick={handleWineAct}>
                        <img src={ArrowDownWhite} alt="" />
                        <span>Vinos</span>
                      </li>
                      <li onClick={handleJuiceAct}>
                        <img src={ArrowDownWhite} alt="" />
                        <span>Jugos y Bebidas</span>
                      </li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </section>

          <section className="CocktailAux-title" ref={coctail}>
            <div className="CocktailAux-content">
              <Fade ssrFadeout>
                <h2>Cócteles de la casa</h2>
              </Fade>

              <div className="CocktailAux-content__arrow">
                <Fade ssrFadeout>
                  <img src={ArrowDown} alt="" loading="lazy" />
                </Fade>
              </div>
            </div>
          </section>

          {/* Cócteles de la casa */}
          {coctailNormal.length >= 1 ? (
            <section className="Cocktail-section">
              {coctailNormal.map((cocktail) => (
                <div className="Cocktail-content" key={cocktail.id}>
                  {cocktail.acf.imagen.url ? (
                    <div className="Cocktail-content_img">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={cocktail.acf.imagen.url}
                        />
                        <Fade ssrFadeout>
                          <img
                            src={cocktail.acf.imagen.url}
                            alt=""
                            loading="lazy"
                          />
                        </Fade>
                      </picture>
                    </div>
                  ) : undefined}

                  <div className="Cocktail-content__information">
                    <div className="Cocktail-content__description">
                      <Fade ssrFadeout>
                        <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                        <p>{cocktail.acf.descripcion}</p>
                      </Fade>
                    </div>

                    <div className="Cocktail-content__price">
                      <Fade ssrFadeout>
                        <h3>
                          $<span>{cocktail.acf.precio}</span>
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="CocktailAux-title">
            <div className="CocktailAux-content">
              <Fade ssrFadeout>
                <h2>
                  Clásicos <br /> & refrescantes
                </h2>
              </Fade>

              <div className="CocktailAux-content__arrow">
                <Fade ssrFadeout>
                  <img src={ArrowDown} alt="" loading="lazy" />
                </Fade>
              </div>
            </div>
          </section>

          {/* Clásicos & Refrescantes */}
          {coctailClassic.length >= 1 ? (
            <section className="Cocktail-section">
              {coctailClassic.map((cocktail) => (
                <div className="Cocktail-content" key={cocktail.id}>
                  {cocktail.acf.imagen.url ? (
                    <div className="Cocktail-content_img">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={cocktail.acf.imagen.url}
                        />
                        <Fade ssrFadeout>
                          <img
                            src={cocktail.acf.imagen.url}
                            alt=""
                            loading="lazy"
                          />
                        </Fade>
                      </picture>
                    </div>
                  ) : undefined}

                  <div className="Cocktail-content__information">
                    <div className="Cocktail-content__description">
                      <Fade ssrFadeout>
                        <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                        <p>{cocktail.acf.descripcion}</p>
                      </Fade>
                    </div>

                    <div className="Cocktail-content__price">
                      <Fade ssrFadeout>
                        <h3>
                          $<span>{cocktail.acf.precio}</span>
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="CocktailAux-title" ref={wine}>
            <div className="CocktailAux-content">
              <Fade ssrFadeout>
                <h2>Nuestros vinos</h2>
              </Fade>

              <div className="CocktailAux-content__arrow">
                <Fade ssrFadeout>
                  <img src={ArrowDown} alt="" loading="lazy" />
                </Fade>
              </div>
            </div>
          </section>

          <section className="CocktailAuxSmall-section">
            <div className="CocktailAuxSmall-content">
              <Fade ssrFadeout>
                <h3>Espumantes</h3>
              </Fade>
            </div>
          </section>

          {/* Espumantes */}
          {champagne.length >= 1 ? (
            <section className="Cocktail-section">
              {champagne.map((cocktail) => (
                <div className="Cocktail-content" key={cocktail.id}>
                  {cocktail.acf.imagen.url ? (
                    <div className="Cocktail-content_img">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={cocktail.acf.imagen.url}
                        />
                        <Fade ssrFadeout>
                          <img
                            src={cocktail.acf.imagen.url}
                            alt=""
                            loading="lazy"
                          />
                        </Fade>
                      </picture>
                    </div>
                  ) : undefined}

                  <div className="Cocktail-content__information">
                    <div className="Cocktail-content__description">
                      <Fade ssrFadeout>
                        <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                        <p>{cocktail.acf.descripcion}</p>
                      </Fade>
                    </div>

                    <div className="Cocktail-content__price">
                      <Fade ssrFadeout>
                        <h3>
                          $<span>{cocktail.acf.precio}</span>
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="CocktailAuxSmall-section">
            <div className="CocktailAuxSmall-content">
              <Fade ssrFadeout>
                <h3>Vinos Tintos:</h3>
              </Fade>
            </div>
          </section>

          {/* Vinos Tinto */}
          {wineClassic.length >= 1 ? (
            <section className="Cocktail-section">
              {wineClassic.map((cocktail) => (
                <div className="Cocktail-content" key={cocktail.id}>
                  {cocktail.acf.imagen.url ? (
                    <div className="Cocktail-content_img">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={cocktail.acf.imagen.url}
                        />
                        <Fade ssrFadeout>
                          <img
                            src={cocktail.acf.imagen.url}
                            alt=""
                            loading="lazy"
                          />
                        </Fade>
                      </picture>
                    </div>
                  ) : undefined}

                  <div className="Cocktail-content__information">
                    <div className="Cocktail-content__description">
                      <Fade ssrFadeout>
                        <span>
                          {htmlEntities.decode(cocktail.acf.title_small)}
                        </span>
                        <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                        <p>{cocktail.acf.descripcion}</p>
                      </Fade>
                    </div>

                    <div className="Cocktail-content__price">
                      <Fade ssrFadeout>
                        <h3>
                          $<span>{cocktail.acf.precio}</span>
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="CocktailAuxSmall-section">
            <div className="CocktailAuxSmall-content">
              <Fade ssrFadeout>
                <h3>Vinos Blancos:</h3>
              </Fade>
            </div>
          </section>

          {/* Vinos Blancos */}
          {whiteWine.length >= 1 ? (
            <section className="Cocktail-section">
              {whiteWine.map((cocktail) => (
                <div className="Cocktail-content" key={cocktail.id}>
                  {cocktail.acf.imagen.url ? (
                    <div className="Cocktail-content_img">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={cocktail.acf.imagen.url}
                        />
                        <Fade ssrFadeout>
                          <img
                            src={cocktail.acf.imagen.url}
                            alt=""
                            loading="lazy"
                          />
                        </Fade>
                      </picture>
                    </div>
                  ) : undefined}

                  <div className="Cocktail-content__information">
                    <div className="Cocktail-content__description">
                      <Fade ssrFadeout>
                        <span>
                          {htmlEntities.decode(cocktail.acf.title_small)}
                        </span>
                        <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                        <p>{cocktail.acf.descripcion}</p>
                      </Fade>
                    </div>

                    <div className="Cocktail-content__price">
                      <Fade ssrFadeout>
                        <h3>
                          $<span>{cocktail.acf.precio}</span>
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="CocktailAuxSmall-section">
            <div className="CocktailAuxSmall-content">
              <h3>Cervezas:</h3>
            </div>
          </section>

          {/* Cervezas */}
          {beer.length >= 1 ? (
            <section className="Cocktail-section no-image">
              {beer.map((cocktail) => (
                <div className="Cocktail-content" key={cocktail.id}>
                  {cocktail.acf.imagen.url ? (
                    <div className="Cocktail-content_img">
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={cocktail.acf.imagen.url}
                        />
                        <Fade ssrFadeout>
                          <img
                            src={cocktail.acf.imagen.url}
                            alt=""
                            loading="lazy"
                          />
                        </Fade>
                      </picture>
                    </div>
                  ) : undefined}

                  <div className="Cocktail-content__information">
                    <div className="Cocktail-content__description">
                      <Fade ssrFadeout>
                        <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                      </Fade>
                    </div>

                    <div className="Cocktail-content__price">
                      <Fade ssrFadeout>
                        <h3>
                          $<span>{cocktail.acf.precio}</span>
                        </h3>
                      </Fade>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          ) : (
            <Fade ssrFadeout>
              <span>No hay productos</span>
            </Fade>
          )}

          <section className="CocktailAuxSmall-section" ref={juice}>
            <div className="CocktailAuxSmall-content">
              <Fade ssrFadeout>
                <h3>Jugos y bebidas:</h3>
              </Fade>
            </div>
          </section>

          {/* Jugos & Bebidas */}
          <section className="Cocktail-section no-image">
            {juiceDrinks.map((cocktail) => (
              <div className="Cocktail-content" key={cocktail.id}>
                <div className="Cocktail-content__information">
                  <div className="Cocktail-content__description">
                    <Fade ssrFadeout>
                      <h2>{htmlEntities.decode(cocktail.title.rendered)}</h2>
                    </Fade>
                  </div>

                  <div className="Cocktail-content__price">
                    <Fade ssrFadeout>
                      <h3>
                        $<span>{cocktail.acf.precio}</span>
                      </h3>
                    </Fade>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
