/* eslint-disable no-return-assign */
import React from "react";

import mapImg from "../static/mapa.png";

import "../styles/components/Map.scss";

function Map() {
  return (
    <div id="map">
      <a
        target="_blank"
        href="https://www.google.com/maps/place/La+Caperucita+y+el+Lobo/@-33.0479078,-71.6202463,16.82z/data=!4m5!3m4!1s0x9689e1277d91e29d:0x438e81fabdefe8a3!8m2!3d-33.0482569!4d-71.6201899"
        rel="noreferrer"
      >
        <img src={mapImg} alt="" />
      </a>
    </div>
  );
}

export default Map;
