import React from "react";
import Routes from "../routes/App";

import "../styles/components/App.scss";

function App() {
  return <Routes />;
}

export default App;
