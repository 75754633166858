import React, { useEffect, useState } from 'react';

// Components
import Fade from 'react-reveal/Fade';
import config from 'react-reveal/globals';
import Loader from '../components/Loader';
import Map from '../components/Map';

// Styles
import '../styles/containers/AboutUs.scss';

// Statis assets
import logoBlack from '../static/logo-black.svg';
import iconCaperucita from '../static/icon-caperucita.svg';
import iconLobo from '../static/icon-lobo.svg';
// import StoryHead from "../static/story-images/Story-hero.png";
import StoryHeadDesktop from '../static/story-images/Story-hero-desktop.png';
import StoryHeadMobile from '../static/story-images/Story-hero-mobile.png';
import StoryAuxDesktop from '../static/story-images/story-aux-desktop.png';
import StoryContent1 from '../static/story-images/Story-content1.png';
import StoryContent1Desktop from '../static/story-images/Story-content1-desktop.png';
import StoryContent2 from '../static/story-images/Story-content2.png';
import StoryContent2Desktop from '../static/story-images/Story-content2-desktop.png';
import StoryContent3 from '../static/story-images/Story-content3.png';
import StoryContent3Desktop from '../static/story-images/Story-content3-desktop.png';
import AuxFloatImage from '../static/story-images/IMG_20190505_133402380.jpg';

config({ ssrFadeout: true });

export const AboutUs = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <section className='Story-section'>
            <div className='Story-content first'>
              <div className='Story-content__image'>
                <picture>
                  <source media='(min-width: 768px)' srcSet={StoryHeadDesktop} />
                  <Fade ssrFadeout>
                    <img src={StoryHeadMobile} alt='' />
                  </Fade>
                </picture>
              </div>

              <div className='Story-content__information'>
                <div className='Story-information__logo'>
                  <Fade ssrFadeout big>
                    <img src={logoBlack} alt='' />
                  </Fade>
                </div>

                <div className='Story-content__description'>
                  <Fade ssrFadeout big>
                    <h2>
                      Como volver <br /> al hogar.
                    </h2>
                    <p>
                      Nos hemos propuesto como restaurant rescatar esos sabores que remitan a un hogar. Somos una
                      familia, que abre sus puertas a todo aquel que desee disfrutar de una propuesta gastronómica que
                      reinventa la cocina tradicional, sintiéndose como si estuviese en casa.
                    </p>
                  </Fade>
                </div>

                <div className='Story-information__scroll'>
                  <Fade ssrFadeout bottom>
                    <img src={iconCaperucita} alt='' />
                  </Fade>
                </div>
              </div>
            </div>

            <div className='Story-content auxFloat auxFloatFirst'>
              <div className='StoryAux-content'>
                <picture>
                  <source media='(min-width: 768px)' srcSet={StoryAuxDesktop} />
                  <Fade ssrFadeout>
                    <img src={StoryAuxDesktop} alt='' />
                  </Fade>
                </picture>

                <div className='storyAux-floatInfo'>
                  <Fade ssrFadeout cascade bottom>
                    <div className='floatInfo-left'>
                      <h1>
                        LEO <br /> DE LA IGLESIA
                      </h1>
                      <span>
                        <strong>CHEF</strong>
                      </span>
                    </div>
                    <div className='floatInfo-rigth'>
                      <h1>CARO GATICA</h1>
                      <span>
                        <strong>REPOSTERA</strong>
                      </span>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>

            <div className='Story-content auxFloat'>
              <div className='StoryAux-float'>
                <div className='StoryAux-float__information'>
                  <div className='StoryAux-float__image'>
                    <Fade ssrFadeout>
                      <img src={AuxFloatImage} alt='' />
                    </Fade>
                  </div>

                  <div className='StoryAux-float__description'>
                    <Fade ssrFadeout>
                      <h2>El amor como ingrediente en cada detalle.</h2>

                      <p>
                        El corazón del restaurant es el equilibrio perfecto entre lo salvaje y audaz de las
                        preparaciones de Leo (el Lobo) y la dulce coquetería de los postres de Caro (la Caperucita).
                        Esta relación que trasciende lo culinario (Caro y Leo son matrimonio) crea un ambiente familiar,
                        que invita a sus comensales a ser parte y disfrutar de una cocina cuyo principal ingrediente es
                        el amor.
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>

            <div className='Story-content second'>
              <div className='Story-content__image'>
                <picture>
                  <source media='(min-width: 768px)' srcSet={StoryContent1Desktop} />
                  <Fade ssrFadeout>
                    <img src={StoryContent1} alt='' />
                  </Fade>
                </picture>
              </div>

              <div className='Story-content__information'>
                <div className='Story-information__logo'>
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt='' />
                  </Fade>
                </div>

                <div className='Story-content__description'>
                  <Fade ssrFadeout big>
                    <h2>Una versión moderna de lo clásico.</h2>
                    <p>
                      Nuestra propuesta es un rescate de aquellos sabores de antaño, reinterpretados, con un toque
                      sorprendente. Ingredientes simples que otorgan sabores únicos en una experiencia sublime.
                    </p>
                  </Fade>
                </div>

                <div className='Story-information__scroll'>
                  <Fade ssrFadeout bottom>
                    <img src={iconLobo} alt='' />
                  </Fade>
                </div>
              </div>
            </div>

            <div className='Story-content third'>
              <div className='Story-content__image'>
                <picture>
                  <source media='(min-width: 768px)' srcSet={StoryContent2Desktop} />
                  <Fade ssrFadeout>
                    <img src={StoryContent2} alt='' />
                  </Fade>
                </picture>
              </div>

              <div className='Story-content__information'>
                <div className='Story-information__logo'>
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt='' />
                  </Fade>
                </div>

                <div className='Story-content__description'>
                  <Fade ssrFadeout big>
                    <h2>Una casona en el corazón de Valparaíso.</h2>
                    <p>
                      Esta casa perteneció a la abuela de Caro (nuestra Caperucita). Construída en 1954, albergó a tres
                      generaciones de la familia, siendo centro de reuniones y celebraciones familiares. Hoy acoge a
                      todos quienes desean disfrutar de sabores y momentos inolvidables.
                    </p>
                  </Fade>
                </div>

                <div className='Story-information__scroll'>
                  <Fade ssrFadeout bottom>
                    <img src={iconCaperucita} alt='' />
                  </Fade>
                </div>
              </div>
            </div>

            <div className='Story-content fourth'>
              <div className='Story-content__image'>
                <picture>
                  <source media='(min-width: 768px)' srcSet={StoryContent3Desktop} />
                  <Fade ssrFadeout>
                    <img src={StoryContent3} alt='' />
                  </Fade>
                </picture>
              </div>

              <div className='Story-content__information'>
                <div className='Story-information__logo'>
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt='' />
                  </Fade>
                </div>

                <div className='Story-content__description'>
                  <Fade ssrFadeout big>
                    <h2>Un cuento reinventado.</h2>
                    <p>
                      ¿Por qué el nombre La Caperucita y el Lobo? Porque somos una familia que ama las historias y la
                      nuestra parte en la casa de la abuelita. Aquí es donde el Lobo fue domesticado y la Caperucita
                      fundó su hogar, con un par de bellos niños, guardianes del huerto de los ingredientes secretos.
                      Historias que se relatan en cada plato.
                    </p>
                  </Fade>
                </div>

                <div className='Story-information__scroll'>
                  <Fade ssrFadeout bottom>
                    <img src={iconLobo} alt='' />
                  </Fade>
                </div>
              </div>
            </div>
            {/*
            <div className='Story-content virtualTour'>
              <div className='Story-virtualTour__information'>
                <div className='Story-virtualTour__video'>
                  <div className='Story-information__logo'>
                    <Fade ssrFadeout>
                      <img src={logoBlack} alt='' />
                    </Fade>
                  </div>

                  <Fade ssrFadeout>
                    <iframe
                      src='https://my.matterport.com/show/?m=1gR9FDNnfCE'
                      title='tour'
                      frameBorder='0'
                      allowFullScreen
                      allow='xr-spatial-tracking'
                    />
                  </Fade>
                </div>

                <div className='Story-virtualTour__description'>
                  <Fade ssrFadeout big>
                    <h2>
                      Recorrido <br /> Virtual.
                    </h2>
                    <p>
                      Recorre aquí virtualmente nuestro restaurant. Cada uno de nuestros rincones esconde parte de
                      nuestro cuento.
                    </p>
                  </Fade>
                </div>
              </div>
            </div> */}

            <div className='Story-content map'>
              <div className='StoryMap-content__information'>
                <div className='Story-information__logo'>
                  <Fade ssrFadeout>
                    <img src={logoBlack} alt='' />
                  </Fade>
                </div>

                <div className='StoryMap-content__description'>
                  <Fade ssrFadeout>
                    <h2>
                      La magia <br /> de Valparaíso.
                    </h2>
                    <p>
                      Nos enorgullece ser uno más de los rincones mágicos de esta bella ciudad. <br /> Valparaíso es
                      parte de nuestra identidad y nuestros sabores.
                    </p>
                  </Fade>
                </div>
              </div>

              <Fade ssrFadeout>
                <div className='StoryMap-location'>
                  <Map />
                </div>
              </Fade>
            </div>
          </section>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
