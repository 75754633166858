import React from "react";
import ReactLoading from "react-loading";

import Fade from "react-reveal/Fade";
import config from "react-reveal/globals";

import "../styles/components/Loader.scss";

config({ ssrFadeout: true });

function Loader() {
  return (
    <Fade ssrFadeout big>
      <div className="Loader-section ">
        <ReactLoading type="cylon" color="#2E3A40" height="0" width="5%" />
      </div>
    </Fade>
  );
}

export default Loader;
